@tailwind base;
@tailwind components;
@tailwind utilities;
.nav-area {
  display: flex;
  align-items: center;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items>a,
.menu-items button {
  text-align: left;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  box-shadow: 0 0 4px 0px #fff;
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 16rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  background-color: #000;
}

.dropdown.show {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .dropdown.show {
    display: block;
  }

  .dropdown {
    left: 0;
  }

  .menu-items a {
    width: 100% !important;
  }
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.vertical-timeline-element-content {
  background-color: #77c1dd14 !important;
}



.vertical-timeline-element-content h2 {
  color: #77c1dd !important;
  font-size: 1.8rem !important;
}

@media only screen and (max-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 1.2rem !important;
  }
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  top: 40%;
}



.bn5 {
  padding: 8px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.ant-row {
  display: flex;
  align-items: center;
}

.bn5:before {
  content: "";
  background: linear-gradient(45deg,
      #ff7e79,
      #58f658,
      #d00241,
      #ff9e98,
      #6193ff,
      #a51bff,
      #93d6f8,
      #00ce8d,
      #ff5453);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowingbn5 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowingbn5 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.bn5:active {
  color: #000;
}

.bn5:active:after {
  background: #191919;
}

.bn5:hover:before {
  opacity: 1;
}

.bn5:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #191919;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.bn6 {
  /* padding: 8px; */
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.bn6:before {
  content: "";
  background: linear-gradient(45deg,
      #ff7e79,
      #58f658,
      #d00241,
      #ff9e98,
      #6193ff,
      #a51bff,
      #93d6f8,
      #00ce8d,
      #ff5453);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowingbn5 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.bn6:active {
  color: #000;
}

.bn6:active:after {
  background: #191919;
}

.bn6:before {
  opacity: 1;
}

.bn6:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #191919;
  left: 0;
  top: 0;
  border-radius: 10px;
}


.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background: #000;
  color: #e7b30a;
  text-align: center;
}

.table-responsive {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .table-responsive {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #e7b30a;
    border-radius: 5px;
  }

  .table-responsive::-webkit-scrollbar-track {
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
  }
}

.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
  text-align: center;
}


.ant-form-item {
  margin-bottom: 2px !important;
}

.inputbox {
  width: 20vw !important;
}

.inline-block {
  display: inline-block;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgb(226 178 0);
}

.ant-pagination .ant-pagination-item-active a {
  color: #212121;
  background: rgb(226 178 0);
  border: none;
}

.ant-pagination .ant-pagination-item-active {
  border: none;
  background-color: #212121;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-title, .ant-table-wrapper .ant-table.ant-table-small .ant-table-footer, .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th, .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td, .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th, .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td{
  padding: 0 8px !important
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: #e2b200;
}

.bg-gradient {
  background: linear-gradient(135deg, #212121 50%, #e2b200 ); 
}

.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #e2b200!important;
  pointer-events: none;
}
@keyframes slideInRight {
  from {
    transform: translateX(100%); 
  }
  to {
    transform: translateX(0);
  }
}

.form-container {
   /* border: 4px solid #e2b200;
  animation: slideInRight 1s;  */
  background: rgba( 255, 255, 255, 0.35 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 12.5px );
-webkit-backdrop-filter: blur( 12.5px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 )
} 
.ant-pagination .ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgb(255 255 255);
  transition: none;
}

.ant-table-wrapper .ant-table-filter-trigger{
  color: #e7b30a !important;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
  display: none  !important;
}
.ant-btn-primary {
  color: #fff;
  background-color: #e7b30a;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}