.reportModal{
    width: 1100px  !important;
}

.ant-modal .ant-modal-content{
    /* background-image: none  !important; */
    background-image: none !important;
    background-color: #ffffff  !important;
}
.ant-modal-footer{
    display: none !important;
}
.button.ion-activatable.ion-focusable {
    display: none !important;
}
.apexcharts-toolbar {
    position: absolute;
    z-index: 11;
    max-width: 176px;
    text-align: right;
    border-radius: 3px;
    padding: 0 6px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none!important;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
    display: none;
}