@font-face {
    font-family: montserrat;
    src: url('../src/fonts/Montserrat-VariableFont_wght.ttf');
}

.font_secondary {
    font-family: montserrat;
}

@font-face {
    font-family: solway;
    src: url('../src/fonts/Solway-Medium.ttf');
}

.font_primary {
    font-family: solway;
}

.active-style {
    color: #e7b30a !important;
    font-family: solway;
    font-weight: 400;
}

.active {
  color: #e7b30a !important;
  font-family: solway;
  font-weight: 400;
}

.slick-dots li button:before {
    color: #e7b30a !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ddb100;
    text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgb(255 255 255);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    display: flex;
}
.ant-tabs-tab:hover {
    color: #ddb100;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-1vtf12y).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-1vtf12y).ant-tabs .ant-tabs-tab-btn:active, :where(.css-dev-only-do-not-override-1vtf12y).ant-tabs .ant-tabs-tab-remove:active {
    color: #ddb100;
}
.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #ddb100!important;
    pointer-events: none;
}


